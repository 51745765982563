import React from 'react';
import Header from '../components/header';
import Footer from '../components/fotter';
const themes = require('../configs/fomations.json')

const Abonnement = () => {
    return (
    
       
        <Footer />
    );
};

export default Abonnement;