import React from 'react'
function MeCertifier() {
    return (
    <div className="container my-5 mecertifier">
    <div className="row align-items-center">
        <div className="col-md-6">
            <div className="img">
            <img src="/img/mecertifier.png" alt="me certifier" />
            </div>
        </div>
        <div className="col-md-6">
            <div className="text">
                <div className="title">
                    <h1>Se certifier, c'est valoriser ses compétences et</h1>
                    <h1><span>renforcer sa crédibilité</span></h1>
                </div>
                <div className="content">
                    <p>
                    Avec CertyUP, tu peux obtenir des certifications reconnues qui attestent de ton expertise et de ton engagement dans l’apprentissage continu.
                    </p>
                    <p>
                    Que tu souhaites approfondir tes connaissances, te démarquer sur le marché de l’emploi ou valider officiellement ton savoir-faire, nos certifications sont adaptées à tous les profils et secteurs d’activité. 
                    </p>
                    <p><span>Fais reconnaître tes compétences dès aujourd’hui !</span></p>
                </div>
            </div>
        </div>
    </div>
    </div>  );
}

export default MeCertifier;