import React from 'react'
import Header from '../../components/header';
import Hero from './Hero';
import DomaineSection from '../../components/domaineSection';
import MeCertifier from '../../components/MeCertifier';
import Certificats from '../../components/certificats';
import Footer from '../../components/fotter';
import WhatsAppWidget from '../../components/WhatsAppWidget';
import Parteners from '../../components/parteners';
const themes = require('../../configs/fomations.json')
function Certif() {
    return (
        <>
        <Header domaines={themes.formations} />
            <Hero />
            <MeCertifier />
            <Certificats domaines={themes.formations} />

            <Parteners /> 
            <Footer />
            <WhatsAppWidget/>
        </>
     );
}
export default Certif;