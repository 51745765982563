import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

/*
  resources:
  1- https://www.npmjs.com/package/react-owl-carousel
  2- https://github.com/seal789ie/react-owl-carousel
*/

const Parteners = () => {
  const options = {
    loop: true,
    items: 1,
    autoplay: true,
    autoplayTimeout: 1000,
    dots: false,
    center: false,
    responsive: {
      0: {
        items: 3, // Affiche un seul élément à la fois pour les écrans de moins de 768px de largeur
      },
      768: {
        items: 7, // Affiche 4 éléments à la fois pour les écrans de 768px et plus de largeur
      },
    },
  };

  return (
    <>
    <div className="m-5">

    </div>
      <h4 style={{textAlign:"center", color:"#030B51", fontFamily:"Montserrat", fontWeight:"700", fontSize:"1.6 rem"}}>ILS NOUS FONT CONFIANCE </h4>
      <div className="py-2 mt-4" style={{ backgroundColor: "#F4F5FB" }}>
        <OwlCarousel className="owl-theme partener" {...options}>
          <div>
            <img
              src="./img/1.png"
              alt=""
              style={{ maxWidth: "200px", height: "auto" }}
            />
          </div>
          <div>
            <img
              src="./img/8.png"
              alt=""
              style={{ maxWidth: "200px", height: "auto" }}
            />
          </div>
          <div>
            <img
              src="./img/unnamed.jpg"
              alt=""
              style={{ maxWidth: "200px", height: "auto" }}
            />
          </div>
          <div>
            <img
              src="./img/6.png"
              alt=""
              style={{ maxWidth: "200px", height: "auto" }}
            />
          </div>
          <div>
            <img
              src="./img/5.png"
              alt=""
              style={{ maxWidth: "200px", height: "auto" }}
            />
          </div>
          <div>
            <img
              src="./img/4.png"
              alt=""
              style={{ maxWidth: "200px", height: "auto" }}
            />
          </div>
          <div>
            <img
              src="./img/10.png"
              alt=""
              style={{ width: "200px", height: "auto" }}
            />
          </div>
        </OwlCarousel>
      </div>
    </>
  );
};
export default Parteners;
