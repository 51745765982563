import { FormControl, Grid, TextareaAutosize } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import emailjs from 'emailjs-com';


function Contact() {
 
  const [formData, setFormData] = useState({
    prenom: '',
    nom:'',
    email: '',
    message: '',
    telephone:''
  }); 
  const [responseMessage, setResponseMessage] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();

    // Configuration des paramètres EmailJS
    const serviceId = 'service_oiy5o6e';
    const templateId = 'template_1x6jbbd';
    const userId = 'VC1dqXh3DrUizsu7x';

    // Envoi de l'email via EmailJS
    emailjs.send(serviceId, templateId, formData, userId)
      .then((response) => {
        setResponseMessage('Votre message a été envoyé avec succès !');
        setFormData({
          prenom: '',
          nom: '',
          email: '',
          message: '',
          telephone: ''
        });
      })
      .catch((error) => {
        setResponseMessage('Erreur lors de l\'envoi du message.');
        console.error('Erreur lors de l\'envoi de l\'email :', error);

      });
  };

  useEffect(() => {
    if (responseMessage) {
      const timer = setTimeout(() => {
        setResponseMessage('');
      }, 2000); // 2 secondes

      return () => {
        clearTimeout(timer);
      };
    }
  }, [responseMessage]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };
    
    const sectionRef = useRef(null);
    return (
        <div className="contact_section" ref={sectionRef} id="contact">
            <div className="row justify-content-center d-flex flex-column align-items-center">
                <div className="col-md-12">
                <div className="form">
                    <div className="titleSection">
                        <h3 className="text-center text1">Tu as des questions</h3>
                        <h2 className="text-center text2 mb-4">Contacte-nous</h2>
                    </div>
            <form onSubmit={handleSubmit} className="text-center">
              <Grid container spacing={2} className="d-flex justify-content-center" style={{ boxShadow: 'none' }}>
                <Grid item xs={10} sm={5}>
                  <input
                    autoComplete="fname"
                    name="prenom"
                    type="text"
                    className="form-control"
                    id="prenom"
                    placeholder="Prénom"
                    onChange={handleChange}
                    value={formData.prenom}
                  />
                </Grid>
                <Grid item xs={10} sm={5}>
                  <input
                    autoComplete="fname"
                    name="nom"
                    type="text"
                    className="form-control"
                    id="nom"
                    placeholder="Nom"
                    onChange={handleChange} 
                    value={formData.nom}
                  />
                </Grid>
                <Grid item xs={10} sm={5}>
                  <input
                    autoComplete="fname"
                    name="email"
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="E-mail"
                    required
                    onChange={handleChange} value={formData.email}
                  />
                </Grid>
                <Grid item xs={10} sm={5}>
                  <input
                    autoComplete="fname"
                    name="telephone"
                    type="tel"
                    className="form-control"
                    id="telephone"
                    placeholder="Téléphone"
                    onChange={handleChange}
                     value={formData.telephone}
                  />
                </Grid>
                <Grid item xs={10} sm={10}>
                    <FormControl fullWidth>
                        <TextareaAutosize
                        placeholder="Message..."
                        minRows={2}
                        id="message"
                        name="message"
                        onChange={handleChange}
                         value={formData.message}
                    />
                    </FormControl>
                </Grid>
              </Grid>
              <p className="text-center mt-4" style={{color:"white"}}>{responseMessage}</p>
              <Button variant="contained" type="submit" className="btnenvoyer shadow mb-4">
                Envoyer
              </Button>
            </form>
            
            
                </div>
                </div>
            </div>
        </div>

    );
}

export default Contact;












  