import { useParams } from "react-router-dom";
import Footer from "../components/fotter";
import Header from "../components/header";
import Inscription from "../components/inscription";
import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import WhatsAppWidget from "../components/WhatsAppWidget";

function Formation(props) {
  const { id } = useParams();
  const { listeformations } = props;
  let formation = listeformations.filter((formation) => formation.id == id);
  formation = formation[0];
  return (
    <>
      <Header domaines={listeformations} />
      <div className="topformation">
        <div className="title">
          <div className="container">
            <div className="row justify-content-start">
              <div className="col-md-6">
                <h4 className="text-uppercase pb-4 formationname">
                  {" "}
                  {formation.name}{" "}
                </h4>
                {/* <h5 className="style mb-4">
                  Durée : <span className="fw-bold">{formation.duree}</span>
                </h5>
                <h5 className="style mb-4">
                  Tarif :{" "}
                  <span className="fw-bold">{formation.tarif} fcfa</span>
                </h5>
                <h5 className="style mb-4">
                  Date des exames :{" "}
                  <span className="fw-bold">{formation.examen}</span>
                </h5> */}
                {/* {
                        formation.certifications ?
                            <div className="d-flex align-items-center mt-4 py-1 certfication">
                                 <div className="icon">
                                    <lord-icon
                                        src="https://cdn.lordicon.com/ssdupzsv.json"
                                        trigger="loop"
                                        colors="primary:white,secondary:white"
                                        stroke="100"
                                        style={{width:"70px",height:"70px"}}>
                                    </lord-icon>
                                 </div>
                                 <div className="text">
                                    <ul style={{textAlign : "left", listStyle : "square", padding : "0"}}>
                                        {formation.certifications.map((certificat)  =>
                                            <li className="styletexte">{certificat} </li>
                                        )}
                                    </ul>
                            </div>
                       
                            </div>
                        :
                    ""
                    } */}
                <div className=" mt-5">
                  {formation.plaquette ? (
                    <a
                      href={`/files/${formation.plaquette}`}
                      download={formation.name}
                    >
                      <button className="btn btn-primary">
                        Télécharger la plaquette
                      </button>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {formation.objectifs ? (
        <div className="container my-4">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div class="card mb-5">
                <h3 class=" px-4 pt-4 mb-0 fw-bold objectifs">
                  {formation.titre}
                </h3>

                <div class="card-body">
                  <p class="card-text">
                    <ul
                      className="itemsobjectifs"
                      style={{
                        listStyle: "square",
                        listStyleType: "none",
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                        paddingLeft: "0px",
                      }}
                    >
                      {formation.objectifs.map((objectif) => (
                        <li className="my-4 d-flex liste">
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-secondary pt-1 me-3"
                          />{" "}
                          <span style={{ color: "#060E58" }} className="texte">
                            {objectif}
                          </span>
                        </li>
                      ))}
                    </ul>

                    {formation.id < 17 ? (
                      <div className="listinfo">
                        <ul
                          className="itemsobjectifs"
                          style={{
                            listStyle: "square",
                            listStyleType: "none",
                            fontFamily: "Montserrat",
                            fontSize: "15px",
                            paddingLeft: "0px",
                          }}
                        >
                          <li className="my-4 d-flex">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="text-secondary pt-1 me-3"
                            />
                            <span
                              style={{ color: "#060E58" }}
                              className="texte"
                            >
                              {formation.certifications}
                            </span>
                          </li>
                          <li className="my-4 d-flex">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="text-secondary pt-1 me-3"
                            />
                            <span
                              style={{ color: "#060E58" }}
                              className="texte"
                            >
                              {formation.duree}
                            </span>
                          </li>
                          <li className="my-4 d-flex">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="text-secondary pt-1 me-3"
                            />
                            <span
                              style={{ color: "#060E58" }}
                              className="texte"
                            >
                              Date d'examen : {formation.examen}
                            </span>
                          </li>
                          <li className="my-4 d-flex">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="text-secondary pt-1 me-3"
                            />
                            <span
                              style={{ color: "#060E58", fontWeight: "bold" }}
                              className="texte"
                            >
                              Tarif: {formation.tarif} fcfa
                            </span>
                          </li>
                        </ul>
                      </div>
                    ) : null}
                    <div className="textebas">{formation.prerequis}</div>
                  </p>
                </div>
              </div>
              {/* <div class="card mb-3">
                <h3 class="text-primary px-4 pt-4 mb-0 fw-bold text-uppercase">
                   Pré-Requis 
                 
                </h3>
                <div class="card-body">
                  <p
                    class="card-text"
                    style={{ fontFamily: "Montserrat", fontSize: "15px" }}
                  >
                    <span style={{ color: "#060E58" }}>
                      {formation.prerequis}
                    </span>
                  </p>
                </div>
              </div> */}
            </div>
            <div className="col-md-5 d-none d-md-block">
              <Card
                className="cardinscription mx-1 shadow"
                style={{
                  border: "none",
                  marginTop: "-400px",
                  background: "#F4F5FB",
                }}
              >
                <CardActionArea>
                  <div style={{ position: "relative" }}>
                    <CardMedia
                      component="img"
                      style={{ height: "100%", padding: "15px 15px 0px 15px" }}
                      image={formation.photo}
                      alt=""
                      className=""
                    />
                  </div>

                  <CardContent className="d-flex justify-content-between align-items-center">
                    <Inscription formation={formation} />
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
            <div className="col-md-5 d-block d-md-none">
              <Card
                className="cardinscription mx-1 shadow"
                style={{ border: "none", background: "#F4F5FB" }}
              >
                <CardActionArea>
                  <div style={{ position: "relative" }}>
                    <CardMedia
                      component="img"
                      style={{ height: "100%", padding: "15px 15px 0px 15px" }}
                      image={formation.photo}
                      alt=""
                      className=""
                    />
                  </div>

                  <CardContent className="d-flex justify-content-between align-items-center">
                    <Inscription formation={formation} />
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div style={{ borderBottom: "4px solid #F78323" }} className="mb-2"></div>
      <Footer />
      <WhatsAppWidget />
    </>
  );
}

export default Formation;
