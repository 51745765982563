import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { ListGroup } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import "./../styles/accordions.scss";
import { Link } from "react-router-dom";

function Accordions({ data }) {
  const [modules, setModule] = useState(data);
  // const [metiers, setMetier] = useState(metier);

  return (
    <>
      <Accordion>
        {/* <Accordion.Item eventKey="1">
          <Accordion.Header>
            <div
              style={{
                backgroundColor: "rgb(247, 131, 35)",
                width: "50px",
                display: "flex",
                justifyContent: "center",
                height: "100%",
                left: 0,
                top: 0,
                position: "absolute",
                zIndex: 1,
              }}
            >
              <img src="/img/certifie1.svg" alt="" style={{ width: "20px" }} />
            </div>
            <div
              style={{
                marginLeft: "45px",
              }}
            >
              Je me certifie sur un module
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <ListGroup variant="flush">
              <ListGroup.Item
                style={{
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#F78323",
                    marginRight: "10px",
                    borderRadius: "6px",
                  }}
                ></div>
                Lean Six Sigma Green Belt
              </ListGroup.Item>
              <ListGroup.Item
                style={{
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#F78323",
                    marginRight: "10px",
                    borderRadius: "6px",
                  }}
                ></div>
                Lean Six Sigma Black Belt
              </ListGroup.Item>
              <ListGroup.Item
                style={{
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#F78323",
                    marginRight: "10px",
                    borderRadius: "6px",
                  }}
                ></div>
                Agilité, Scrum
              </ListGroup.Item>
              <ListGroup.Item
                style={{
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#F78323",
                    marginRight: "10px",
                    borderRadius: "6px",
                  }}
                ></div>
                Project Management Professionnel (PMP)
              </ListGroup.Item>
              <ListGroup.Item
                style={{
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#F78323",
                    marginRight: "10px",
                    borderRadius: "6px",
                  }}
                ></div>
                Prince2
              </ListGroup.Item>
              <ListGroup.Item
                style={{
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#F78323",
                    marginRight: "10px",
                    borderRadius: "6px",
                  }}
                ></div>
                Lean Management
              </ListGroup.Item>
              <ListGroup.Item
                style={{
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#F78323",
                    marginRight: "10px",
                    borderRadius: "6px",
                  }}
                ></div>
                Business Process management and Notation (BPMN)
              </ListGroup.Item>
              <ListGroup.Item
                style={{
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: "#F78323",
                    marginRight: "10px",
                    borderRadius: "6px",
                  }}
                ></div>
                Power BI
              </ListGroup.Item>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item> */}
       
      </Accordion>
      {modules.certifier.map((moduleItem) => (
        <Accordion>
          <Accordion.Item
            key={moduleItem.id} 
            eventKey={moduleItem.id.toString()}
          >
            <Accordion.Header>
              <div
                style={{
                  backgroundColor: "rgb(247, 131, 35)",
                  width: "50px",
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  left: 0,
                  top: 0,
                  position: "absolute",
                  zIndex: 1,
                }}
              >
                <img src={moduleItem.img} alt="" style={{ width: "20px" }} />
              </div>
              <div
                style={{
                  marginLeft: "45px",
                }}
              >
                Je me certifie sur un {moduleItem.titre}
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <ListGroup variant="flush">
                {moduleItem.contenue.map((list, index) => (
                  <Link to={`/formation/${list.name}-${list.id}`} key={list.id}>
                    <ListGroup.Item
                      key={index}
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: "#F78323",
                          marginRight: "10px",
                          borderRadius: "6px",
                        }}
                      ></div>
                      {list.name}
                    </ListGroup.Item>
                  </Link>
                ))}
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}
    </>
  );
}

export default Accordions;
