import TextAnnimate from "./textAnnimate";

function HeaderSection() {
  return (
    <div className="header_section mt-4 pb-3">
      <div className="container">
        <div className="row">
          <div
            className="col-md-6 textsection"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <div className="text">
              <div className="bloc1">
                <h4 className="text-1">CertyUp est la solution</h4>
                {/*  <h2 className="text-1 nn text-1-1">ALL-IN-ONE</h2> */}
                <div className="cadre">
                  <span className="nn"></span>
                  <h2 className="text-1 text-1-1">ALL-IN-ONE</h2>
                </div>
              </div>
              <div className="bloc2">
                <h5 className="text-3">qui te permet de te FORMER,</h5>
                <h5 className="text-3">CERTIFIER et TROUVER un JOB</h5>
              </div>
              <div className="bloc3">
                <h6 className="text-2">
                  <span className="style1">Avec CertyUp tu es </span>
                  <span className="style2">formé, certifié </span>
                  <span className="style1">et </span>
                </h6>
                <h6 className="text-2">
                  <span className="style2">embauché</span>
                  <span className="style1"> !</span>
                </h6>
              </div>
              <div className="bloc4">
                <div className="search-container">
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Dévelopeur Full Stack"
                  />
                  <button className="search-button">
                    <i className="fas fa-search"></i> Rechercher
                  </button>
                </div>
              </div>
              <TextAnnimate />
            </div>
          </div>
          <div
            className="col-md-6 imgsection text-center"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <div className="img">
              <img
                src="./img/nicebloc.svg"
                alt=""
                className=""
                style={{ width: "750px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderSection;
