import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material";
import { useRef } from "react";
import { Link } from "react-router-dom";
import WordCloud from "./WordCloud";
import Offre from "./Offre";


function DomaineSection(props) {
    const {domaines} = props;
    const sectionRef = useRef(null);
    return (
        <>
<div className="domaine_section" ref={sectionRef} id="jemeforme">
  <div className="domaine_section_item py-4">
    <div className="titleSection text-center py-5">
      <h3 className="texte1">JE ME FORME</h3>
      <h2 className="texte2">
        My <span className="texte2c">SKILLS</span>, My{" "}
        <span className="texte2c">VALUE</span>
      </h2>
      {/* <h6 className="texte3">Développe tes compétences et valorise ton expertise</h6>
      <h6 className="texte3">en te formant aux métiers de demain</h6> */}
    </div>
    <WordCloud/> 
    <Offre/>
    <div className="row my-4 d-flex justify-content-around">
        <div className="col-md-4 text-start">
        <div className="textbloc1">Inscris-toi dès maintenant et profite d’un accès illimité à l’ensemble de la plateforme, avec</div>
        <div className="textbloc2"> tous les contenus et ressources disponibles. <img src="/img/fleche.svg" alt="" className="lafleche ms-2"/></div>
        </div>
        <div className="col-md-4  text-end">
            <a href="https://cloud- .online/" ><button className='btnjemeforme shadow'>Je me forme</button></a>
        </div>
    </div>
  </div>
</div>


        </>
    );
}

export default DomaineSection;