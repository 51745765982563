import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Accordions from "./Accordions";

const Certifier = () => {
  const sectionRef = useRef(null);
  const module = {
    certifier: [
      {
        id: "1",
        titre: "module",
        img: "/img/certifie1.svg",
        contenue: [
          {
            id: 17,
            name: "Lean Six Sigma Green Belt",
          },
          {
            id: 18,
            name: "Lean Six Sigma Black Belt",
          },
          {
            id: 19,
            name: "Agilité, Scrum Master",
          },
          {
            id: 20,
            name: "Project Management Professionnel (PMP)",
          },

          {
            id: 21,
            name: "Prince2",
          },
          
          {
            id: 22,
            name: "Lean Management",
          },
             
          {
            id: 23,
            name: "Business Process management and Notation (BPMN)",
          },
             
          {
            id: 24,
            name: "Power BI",
          },
        ],
      },
      {
        id: "2",
        titre: "métier",
        img: "/img/certifie2.svg",
        contenue: [
          {
            id: 1,
            name: "Sales Performer",
          },
          {
            id: 2,
            name: "Community Manager",
          },
          {
            id: 3,
            name: "Chargé de clientéle",
          },
          {
            id: 4,
            name: "Digital marketer",
          },

          {
            id: 5,
            name: "Manager de proximité",
          },
          
          {
            id: 8,
            name: "Chef de projets",
          },
        ],
      },
    ],
  };

  return (
    <div className="certifier_section py-5" ref={sectionRef} id="jemecertifie">
      <h3 className="text-center jemecertifie mb-5">JE ME CERTIFIE</h3>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 text-start">
            <h2 className="text1">Valorise tes COMPÉTENCES</h2>
            <h2 className="text1">avec des CERTIFICATIONS</h2>
            <h2 className="text2">INTERNATIONALES RECONNUES</h2>
            <h5 className="text3 mt-3">
              Sois compétitif avec la certif qui fera toute la différence !
            </h5>
            <h5 className="text4"></h5>
            {/*  <a
              href="https://certyup.cloud-elearning.online"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btnjemecertifie shadow my-4">
                Je me certifie
              </button>
            </a> */}
            <Accordions data={module} />
          </div>
          <div className="col-md-6 justify-content-center align-items-center cardimg">
            <img
              src="/img/imagecertifier.svg"
              alt=""
              style={{ borderRadius: "15px", maxWidth: "100%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certifier;
