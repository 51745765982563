import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

const Bosser = () => {
    const sectionRef = useRef(null);
    return (
        <div className="bosser_section pt-5" ref={sectionRef} id='jebosse'>
        <div className="row">
            <h2 className='text-center text1'>JE BOSSE</h2>
            <h2 className='text-center text2 mb-2'>My <span className='style1'>SKILLS</span>, My <span className='style1'>JOB</span></h2>
            <h5 className='text-center text3'>Accède au jobboard FindyUp et trouve </h5>
            <h5 className='text-center text4'>le job fait pour toi !</h5>
        </div>
        <div className="row">
            <div className="bloc1 pt-5 position-relative mt-5">
               
                <div className="logo-button">
                    <img src="/img/8.png" alt="Logo" className="logo" style={{width:"250px"}}/><br />
                    <Link><button className='btnjebosse shadow'><div className='bosse d-flex align-items-center justify-content-center'>en maintenance</div> Je bosse <FontAwesomeIcon icon={faArrowRight} /></button></Link>
                </div>
            </div>
        </div>
        <div className="row bloc2 py-5 justify-content-center" style={{ height: "220px" }}>
        <div className="col-2 col-md-1 mx-4 text-center position-relative">
            <span
            className="position-absolute top-0 start-50 translate-middle-x"
            style={{ transform: "translateX(-50%)" }}
            >
                    <div className='shadow p-2 d-flex align-items-center justify-content-center' data-aos="zoom-in-down" data-aos-duration="2000" style={{ borderRadius: "15px" }}>
                        <img src="/img/diploma.png" alt=""  style={{width:"25px"}}/>
                    </div>
                    
            </span>
            <span className="position-absolute bottom-0 start-0 translate-middle-x" style={{ transform: "translateX(-50%)" }}>
                    <div style={{height:"8px",width:"8px",borderRadius:"50%",backgroundColor:"#62E888"}}></div>
            </span>
            
        </div>
        <div className="col-2 col-md-1 mx-4 text-center position-relative">
            <span
            className="position-absolute bottom-0 start-50 translate-middle-x"
            style={{ transform: "translateX(-50%)" }}
            >
                    <div className='shadow p-2 d-flex align-items-center justify-content-center' data-aos="zoom-in-up" data-aos-duration="2000" style={{ borderRadius: "15px" }}>
                        <img src="/img/e-learning.png" alt="" style={{width:"25px"}}/>
                    </div>
            </span>
        </div>
        <div className="col-2 col-md-1 mx-4 text-center position-relative">
            <span
            className="position-absolute top-0 start-50 translate-middle-x"
            style={{ transform: "translateX(-50%)" }}
            >
                    <div className='shadow p-2 d-flex align-items-center justify-content-center' data-aos="zoom-in-down" data-aos-duration="2000" style={{ borderRadius: "15px" }}>
                        <img src="/img/globe.png" alt="" style={{width:"25px"}}/>
                    </div>
            </span>
            <span className="position-absolute bottom-0 start-0 translate-middle-x" style={{ transform: "translateX(-50%)" } }>
                    <div style={{height:"10px",width:"10px",borderRadius:"50%",backgroundColor:"#F78323"}}></div>
            </span>
        </div>
        <div className="col-2 col-md-1 mx-4 text-center position-relative">
            <span
            className="position-absolute bottom-0 start-50 translate-middle-x"
            style={{ transform: "translateX(-50%)" }}
            >
                    <div className='shadow p-2 d-flex align-items-center justify-content-center' data-aos="zoom-in-up" data-aos-duration="2000" style={{ borderRadius: "15px" }}>
                        <img src="/img/browser.png" alt="" style={{width:"25px"}}/>
                    </div>
            </span>
            
        </div>
        <div className="col-2 col-md-1 mx-4 text-center position-relative">
            <span
            className="position-absolute top-0 start-50 translate-middle-x"
            style={{ transform: "translateX(-50%)" }}
            >
                    <div className='shadow p-2 d-flex align-items-center justify-content-center' data-aos="zoom-in-down" data-aos-duration="2000" style={{ borderRadius: "15px" }}>
                        <img src="/img/link-alt.png" alt="" style={{width:"25px"}}/>
                    </div>
            </span>
            <span className="position-absolute bottom-0 start-0 translate-middle-x" style={{ transform: "translateX(-50%)" }}>
                <div style={{height:"6px",width:"6px",borderRadius:"50%",backgroundColor:"#016BFF"}}></div>
            </span>
        </div>
        <div className="col-2 col-md-1 mx-4 text-center position-relative">
            <span
            className="position-absolute bottom-0 start-50 translate-middle-x"
            style={{ transform: "translateX(-50%)" }}
            >
                    <div className='shadow p-2 d-flex align-items-center justify-content-center' data-aos="zoom-in-up" data-aos-duration="2000" style={{ borderRadius: "15px" }}>
                        <img src="/img/briefcase.png" alt="" style={{width:"25px"}}/>
                    </div>
            </span>
        </div>
        </div>

    </div>
    
    
    );
};

export default Bosser;