import React, { useState, useRef, useEffect } from 'react';
import { Card, CardActionArea, CardContent } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Offre = () => {
  const [boutonActif, setBoutonActif] = useState(0);
  const [formData, setFormData] = useState({
    nomComplet: '',
    email: '',
    telephone: '',
  });
  const navigate = useNavigate()
  const [dureeChoisie, setDureeChoisie] = useState('1 mois');
  const [prixActuel, setPrixActuel] = useState("25.000");
  const [descriptionActuelle, setDescriptionActuelle] = useState('');

  const inputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Formulaire soumis avec les données :', formData);
  };

  const handleClick = (index) => {
    setBoutonActif(index);
    setDureeChoisie(boutons[index]);
    setPrixActuel(prixParBouton[index]);
    setDescriptionActuelle(descriptions[index]); // Mettre à jour la description actuelle
    inputRef.current.focus();
  };

  const changePage = () => {
    console.log(dureeChoisie)
    console.log(prixActuel)
    navigate("/inscription", { "state": { "duree": dureeChoisie, "prix": prixActuel, "description": descriptionActuelle } })
  }

  const boutons = ['1 mois','12 mois'];
  const prixParBouton = ["6000", "50000"];
  const descriptions = [
    'Pendant 1 mois, aie un accès illimité à la plateforme. Des cours proposés par des experts dans leurs domaines.',
    /* 'Prolongez l’expérience pendant 3 mois et bénéficiez de réductions non négligeables toujours pour un accès illimité.',
    'En 6 mois prenez le temps de développez vos  compétences visées au meilleur prix.', */
    'En 12 mois, en accès illimité, tu as une bonne maîtrise de tes compétences et tu peux envisager de passer des certifications reconnues et recherchées.'
  ];

  useEffect(() => {
    setPrixActuel(prixParBouton[boutonActif]);
    setDescriptionActuelle(descriptions[boutonActif]);
  }, [boutonActif, prixParBouton, descriptions]);

  const sectionRef = useRef(null);

  return (
    <div className="offre_section py-5" ref={sectionRef} id='nosforfaits'>
      <div className="container">
       {/*  <h2 className='titre text-center'>Nos forfaits</h2> */}
        <div className="row mb-5">
          <div className="col-lg-4 bloc mt-5">
            <h2 className='text1'>Développe tes compétences</h2>
            <h2 className='text2'>Booste ta carrière</h2>
            <span className='text3'>Avec CertyUp c’est toi qui décide de la formation et du rythme. Consulte nos offres en illimité sur les compétences les plus demandées du marché.</span>
            <div className='text4 mt-4'>Souscris à :</div>
            <div className='d-flex blocbouton'>
              {boutons.map((bouton, index) => (
                <button
                  key={index}
                  className={`btnformule flex-fill ${boutonActif === index ? 'boutonActif' : ''}`}
                  onClick={() => handleClick(index)}
                >
                  {bouton}
                </button>
              ))}
            </div>
          </div>
          <div className="col-lg-8 d-flex justify-content-end mt-5">
            <Card sx={{ maxWidth: 555, flexWrap: 'nowrap' }} className="cardabonnement mx-1">
              <CardActionArea>
                <CardContent className="row mx-3 sec1">
                  <div className="col-md-7 col-12" > 
                    <h6 className='titredescription'>Description</h6>
                    <span className='description'>{descriptionActuelle}</span>
                  </div>
                  <div className="col-md-5 d-flex flex-column  justify-content-center secprix">
                    <div className="text-center">
                      <h2 className='prix'>{prixActuel}</h2>
                      <span className='fcfa'>FCFA</span>
                      <div className='d-flex justify-content-center mt-4'>
                        <button
                          type="button"
                          className='btnformer'
                          onClick={changePage}
                        >
                          Je me forme <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offre;
