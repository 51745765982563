import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import React from 'react'
function Certificats({domaines}) {
    return ( 
        <div className="domaine_section" id="jemeforme">
  <div className="domaine_section_item py-4"></div>
        <div className="row d-flex justify-content-center align-items-center mt-2"> {/* Utilisation de 'justify-content-center' et 'align-items-center' pour centrer les éléments horizontalement et verticalement */}
        {domaines.map((domaine) => (
          <div key={domaine.id} className="col-sm-6 col-md-6 col-lg-4 col-xl-3 my-4 d-flex justify-content-center px-0">
            <a href={`/formation/${domaine.name}-${domaine.id}`}>
              <Card className="cardformation mx-1">
                {/* <div className="avenir text-center"><h3 className="pt-5">A venir</h3></div> */}
                <CardActionArea>
                  <div className="cardinfo">
                    <CardMedia
                      component="img"
                      style={{ height: "100%", padding: "10px 10px 0px 10px",borderRadius:"25px"}}
                      image={domaine.photo}
                      alt=""
                      className=""
                    />
                  </div>
  
                  <CardContent className="d-flex justify-content-between align-items-center">
                    <div className="nomformation">{domaine.name}</div>
                    <div className="cerclefleche"><FontAwesomeIcon icon={faArrowRight} className="fleche"/></div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </a>
          </div>
        ))}
      </div>
      </div>
     );
}

export default Certificats;